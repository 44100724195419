import * as THREE from "three";
import React, { useRef, useState, useEffect } from "react";
import { Canvas, useFrame, useLoader, useThree } from "@react-three/fiber";
import { EffectComposer } from "@react-three/postprocessing";
import { useCubeTexture, PerformanceMonitor } from "@react-three/drei";
import { useTransform, useSpring, useScroll } from "framer-motion";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";
import { MeshStandardMaterial, Vector3 } from "three";
import { FastAverageColor } from "fast-average-color";

function Icosahderon({ layers, ...props }) {
	const gltf = useLoader(GLTFLoader, "/assets/icosahedron.glb");
	const group = useRef();
	const modelRef = useRef();
	const [lightColor, setLightColor] = useState(new THREE.Color(0, 0, 0));
	const [windowWidth, setWindowWidth] = useState(window.innerWidth);

	const offsetHeight = 0;

	const { scrollY } = useScroll();

	// Transforms scroll and image height values to opacity values
	const yRange = useTransform(
		scrollY,
		[window.innerHeight - offsetHeight, 0],
		[10, 0]
	);
	const yPos = useSpring(yRange, {
		stiffness: 20,
		damping: 5,
		restDelta: 0.001,
	});

	const envMap = useCubeTexture(
		["nx2.png", "nx2.png", "nx2.png", "nx2.png", "nx2.png", "nx2.png"],
		{ path: "/cube/" }
	);
	const { viewport } = useThree();

	useFrame(({ clock, mouse }) => {
		// check if element by id exists or return
		if (!document.getElementById("projects")) return;

		let canvas = document.getElementById("projects").children[1];
		// check if canvas is not a canvas element
		if (canvas.tagName !== "CANVAS") {
			canvas = document.getElementById("projects").children[0];
		}

		const fac = new FastAverageColor();
		fac.getColorAsync(canvas)
			.then((color) => {
				// lerp rgb color
				const lerp = (a, b, n) => (1 - n) * a + n * b;
				const r = lerp(lightColor.r, color.value[0], 0.05);
				const g = lerp(lightColor.g, color.value[1], 0.05);
				const b = lerp(lightColor.b, color.value[2], 0.05);

				setLightColor(new THREE.Color(r, g, b));
			})
			.catch((e) => {
				console.log(e);
			});

		const mousePos = new Vector3(
			(mouse.x * viewport.width) / 2,
			(mouse.y * viewport.height) / 2,
			0
		);

		group.current.position.y = THREE.MathUtils.lerp(
			group.current.position.y,
			-mouse.y / 2 + yPos.current,
			0.05
		);
		group.current.position.x = THREE.MathUtils.lerp(
			group.current.position.x,
			-mouse.x / 2 + windowWidth / 1500,
			0.05
		);

		group.current.rotation.y +=
			0.004 + Math.sin(clock.getElapsedTime()) * 0.002;

		if (mousePos.x === 0 && mousePos.y === 0) {
			return;
		}

		modelRef.current?.traverse((child) => {
			if (child.isMesh && child.basePosition) {
				// get child world position
				const worldPos = new Vector3();
				child.getWorldPosition(worldPos);

				let distance =
					worldPos.distanceTo(
						new Vector3(mousePos.x, mousePos.y, 0)
					) / 3;

				if (distance > 1) {
					distance = 1;
				}

				let xFactor = child.basePosition.x / distance;
				let yFactor = child.basePosition.y / distance;
				let zFactor = child.basePosition.z / distance;

				child.position.x = THREE.MathUtils.lerp(
					child.position.x,
					xFactor,
					0.25
				);
				child.position.y = THREE.MathUtils.lerp(
					child.position.y,
					yFactor,
					0.25
				);
				child.position.z = THREE.MathUtils.lerp(
					child.position.z,
					zFactor,
					0.25
				);
			}
		});
	});

	useEffect(() => {
		modelRef.current.traverse((child) => {
			if (child.isMesh) {
				child.material = new MeshStandardMaterial({
					color: "#101010",
					roughness: 0,
					metalness: 0,
					envMap: envMap,
				});

				if (child.basePosition === undefined) {
					child.basePosition = child.position.clone();
					child.baseRotation = child.rotation.clone();
				}
			}
		});

		// on window resize
		window.addEventListener("resize", () => {
			setWindowWidth(window.innerWidth);
		});

		setInterval(() => {}, 500);
	}, [modelRef, envMap]);

	return (
		<>
			<group
				ref={group}
				rotation={[0.2, 0, 0.2]}
				castShadow
				receiveShadow
			>
				<rectAreaLight
					position={[0, -50, 10]}
					lookAt={[0, 0, 0]}
					intensity={100}
					color={lightColor}
				/>
				<primitive object={gltf.scene} ref={modelRef} scale={1.1} />
			</group>
		</>
	);
}

export default function Home3DScene() {
	const [dpr, setDpr] = useState(1);
	return (
		<Canvas
			frameloop="demand"
			dpr={dpr}
			camera={{ position: [0, 0, 3] }}
			gl={{
				powerPreference: "high-performance",
				alpha: true,
				antialias: false,
				stencil: false,
				depth: false,
			}}
			style={{
				position: "fixed",
				width: "100vw",
				height: "100vh",
				left: 0,
				top: 0,
				zIndex: -10,
				background: "transparent",
				pointerEvents: "none",
			}}
		>
			<PerformanceMonitor
				onIncline={() => setDpr(1)}
				onDecline={() => setDpr(0.5)}
			></PerformanceMonitor>
			<Icosahderon />
			<EffectComposer
				multisampling={32}
				disableNormalPass={true}
			></EffectComposer>
		</Canvas>
	);
}
