import React, { useState, useEffect, useRef, Suspense } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Link, useNavigate } from "react-router-dom";
import ArrowRight from "../assets/images/svg/arrowRight.svg";
import { createRef } from "react";

const galleryAutoTime = 8000;
const projectPath = "/project/";

function preloadVideos(videos) {
	videos.forEach((urls) => {
		if (!urls) return;

		const video = document.createElement("video");
		const sourceMp4 = document.createElement("source");
		const sourceWebm = document.createElement("source");

		sourceMp4.setAttribute("src", `${urls[0]}`);
		sourceMp4.setAttribute("type", "video/mp4");

		sourceWebm.setAttribute("src", `${urls[1]}`);
		sourceWebm.setAttribute("type", "video/webm");

		video.appendChild(sourceMp4);
		video.appendChild(sourceWebm);

		video.load();
	});
}

export default function ProjectsGallery({ projects }) {
	const [selectedProjects, setSelectedProjects] = useState([]);
	const [galleryIndex, setGalleryIndex] = useState(0);
	const [selected, setSelected] = useState(false);
	const backgroundCanvas = useRef(null);
	const timeoutRef = React.useRef(null);
	const navigate = useNavigate();

	function resetTimeout() {
		if (timeoutRef.current) {
			clearTimeout(timeoutRef.current);
		}
	}

	useEffect(() => {
		setSelectedProjects(projects.slice(0, 5));

		// preload videos
		preloadVideos(
			projects.slice(0, 5).map((project) => {
				return project.video;
			})
		);
	}, [projects]);

	// create an automatic gallery
	useEffect(() => {
		resetTimeout();
		timeoutRef.current = setTimeout(() => {
			setGalleryIndex((prevIndex) =>
				prevIndex === selectedProjects.length - 1 ? 0 : prevIndex + 1
			);
		}, galleryAutoTime);

		return () => {
			resetTimeout();
		};
	}, [galleryIndex, selectedProjects]);

	const goToGalleryIndex = (index) => {
		setGalleryIndex(index);
	};

	/**
	 * Native scrollTo with callback
	 * @param offset - offset to scroll to
	 * @param callback - callback function
	 */
	function scrollTo(offset, callback) {
		const fixedOffset = offset.toFixed();
		const onScroll = function () {
			if (window.pageYOffset.toFixed() === fixedOffset) {
				window.removeEventListener("scroll", onScroll);
				callback();
			}
		};

		window.addEventListener("scroll", onScroll);
		onScroll();
		window.scrollTo({
			top: offset,
			behavior: "smooth",
		});
	}

	const handleClick = (e, url, video) => {
		e.preventDefault();
		resetTimeout();

		// wait for videoref to be set

		sessionStorage.setItem(
			"projectData",
			JSON.stringify(projects[galleryIndex])
		);
		sessionStorage.setItem("videoPortal", true);

		scrollTo(window.innerHeight, () => {
			setSelected(true);

			setTimeout(() => {
				// create div with id "videoPortal" and append to body
				let videoPortal = document.createElement("div");
				videoPortal.setAttribute("id", "videoPortal");
				document.body.appendChild(videoPortal);

				videoPortal.appendChild(video);

				navigate(projectPath + url);
			}, 1000);
		});
	};

	const handleCanvasBackground = (video) => {
		var canvas = backgroundCanvas.current;

		if (!backgroundCanvas.current) return;
		var ctx = canvas.getContext("2d");

		canvas.width = video.videoWidth / 10;
		canvas.height = video.videoHeight / 10;

		video.play();

		video.addEventListener(
			"play",
			function () {
				var $this = this; //cache
				(function loop() {
					if (!$this.paused && !$this.ended) {
						ctx.drawImage($this, 0, 0, canvas.width, canvas.height);
						setTimeout(loop, 1000 / 24); // drawing at 24fps
					}
				})();
			},
			0
		);
	};

	const videoRef = createRef();

	return (
		<motion.div id="projects">
			<Suspense fallback={null}>
				<AnimatePresence>
					<motion.canvas
						ref={backgroundCanvas}
						key={galleryIndex}
						initial={{ opacity: 0 }}
						animate={selected ? { opacity: 0 } : { opacity: 0.5 }}
						exit={{ opacity: 0 }}
						transition={{ duration: 1 }}
					></motion.canvas>
				</AnimatePresence>
				{selectedProjects.length > 0 && (
					<AnimatePresence mode="sync">
						<motion.div
							key={"project_" + galleryIndex}
							className={
								selected ? "project selected" : "project"
							}
							initial={{ x: "25vw", opacity: 0 }}
							animate={
								!selected
									? {
											x: 0,
											opacity: 1,
											transition: {
												type: "spring",
												duration: 0.5,
											},
									  }
									: {
											boxShadow: 0,
											x: 0,
											opacity: 1,
											transition: {
												type: "spring",
												duration: 1,
											},
									  }
							}
							exit={
								!selected && {
									x: "-25vw",
									opacity: 0,
									transition: {
										duration: 0.3,
										type: "spring",
									},
								}
							}
						>
							<motion.video
								ref={videoRef}
								muted
								loop
								autoPlay={false}
								transition={{
									width: { bounce: 0, duration: 0.5 },
									type: "spring",
									bounce: 0.9,
									duration: 1,
								}}
								poster={
									selectedProjects[galleryIndex].poster ||
									null
								}
								onLoadedMetadata={(e) => {
									handleCanvasBackground(e.target);
								}}
								crossOrigin="anonymous"
							>
								<source
									src={
										selectedProjects[galleryIndex].video[1]
									}
									type="video/webm"
								/>
								<source
									src={
										selectedProjects[galleryIndex].video[0]
									}
									type="video/mp4"
								/>
								Sorry, your browser doesn't support embedded
								videos.
							</motion.video>
							<motion.div
								className="content"
								animate={
									selected
										? {
												opacity: 0,
												transition: {
													duration: 0.5,
												},
										  }
										: {}
								}
							>
								<motion.h1
									initial={{ opacity: 0, y: -100 }}
									whileInView={{
										opacity: 1,
										y: 0,
										transition: {
											type: "spring",
											duration: 0.6,
											damping: 8,
											delay: 0.4,
										},
									}}
									transition={{ duration: 0 }}
								>
									{selectedProjects[galleryIndex].name}
								</motion.h1>
								<motion.div
									initial={{ opacity: 0, x: -100 }}
									whileInView={
										!selected
											? {
													opacity: 1,
													x: 0,
													transition: {
														type: "spring",
														duration: 0.6,
														damping: 8,
														delay: 0.3,
													},
											  }
											: {}
									}
									transition={{ duration: 0 }}
								>
									<motion.div
										className="link"
										{...(videoRef && {
											onClick: (e) => {
												handleClick(
													e,
													selectedProjects[
														galleryIndex
													].slug,
													videoRef.current
												);
											},
										})}
									>
										<motion.div className="initial">
											<motion.span>
												voir le projet
											</motion.span>
											<motion.img src={ArrowRight} />
										</motion.div>
										<motion.div className="hover">
											<motion.span>
												voir le projet
											</motion.span>
										</motion.div>
									</motion.div>
								</motion.div>
							</motion.div>
						</motion.div>
					</AnimatePresence>
				)}

				<motion.div
					className="navigation"
					initial={{ opacity: 0 }}
					transition={{ duration: 1 }}
					animate={selected ? { opacity: 0 } : { opacity: 1 }}
				>
					{window.innerWidth > 568 &&
						selectedProjects.length &&
						selectedProjects.map((value, index) => {
							return (
								<motion.div
									key={index}
									className={
										galleryIndex === index ? "active" : ""
									}
									onMouseEnter={() => {}}
									onMouseLeave={() => {}}
									onClick={() => {
										goToGalleryIndex(index);
									}}
								>
									{galleryIndex === index && (
										<>
											<motion.div className="inner"></motion.div>
										</>
									)}
									<motion.div className="click"></motion.div>
								</motion.div>
							);
						})}
					<motion.span
						initial={{ opacity: 0 }}
						whileInView={{ opacity: 1 }}
						exit={{ opacity: 0 }}
						id="allProjects"
					>
						<Link to={"/projects/"}>
							<motion.div className="initial">
								<motion.span>voir tous les projets</motion.span>
								<motion.img src={ArrowRight} />
							</motion.div>
							<motion.div className="hover">
								<motion.span>voir tous les projets</motion.span>
							</motion.div>
						</Link>
					</motion.span>
				</motion.div>
			</Suspense>
		</motion.div>
	);
}
